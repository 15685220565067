import React, { Component } from 'react'
import { Box, Grid } from '@mui/material'
import Divider from '@mui/material/Divider';
import TeamTaskInfo from '../TeamTasks/TeamTaskInfo/TeamTaskInfo'
import Tasks from '../TeamTasks/Tasks/Tasks'
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import UserProductivityReport from '../../../WorkHours/UserProductivityReport';
import { getCookie } from '../../../Helper/CookieHelper';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Dialog, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OutOfWork from '../../../WorkHours/OutOfWork/OutOfWork';

const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MyTasks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            workSearchDialog: false
        }
        this.progress = React.createRef();
        this.taskref = React.createRef();
        this.handleClick = this.handleClick.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.refreshSubTender = this.refreshSubTender.bind(this)
    }
    componentDidMount() {
        if (this.props.data) {
            setTimeout(() => this.setState({ isLoading: false }), 1000)
        }
    }

    refreshData() {
        this.props.refreshData()
    }

    refreshSubTender() {
        this.props.refreshSubTender()
    }

    reloadData() {
        this.setState({ isLoading: true }, () => {
            setTimeout(() => this.setState({ isLoading: false }), 1000)
        })
    }

    handleClick(data) {
        if (data) {
            this.props.handleClick(data)
        }
    }

    fetchOps(data) {
        if (this.progress && this.progress.current) {
            this.progress.current.fetchOps(data);
        }
        if (this.taskref && this.taskref.current) {
            this.taskref.current.handleData(data.team_details);
            // this.setState({ isLoading: true }, () => {
            //     this.setState({ isLoading: false })
            // })
        }
    }

    render() {

        const workSearchDialogClose = () => {
            this.setState({ workSearchDialog: false, })
        };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Grid container direction={'row'}>

                        <Box style={{ width: '100%', height: '100%' }}>
                            <TeamTaskInfo data={this.props.data ? this.props.data : null} handleClick={this.handleClick} ref={this.progress} />
                        </Box>

                        <Box mt={2} p={1} style={{ width: '100%' }}>
                            <Divider style={{ width: '100%' }} textAlign='center'>
                            </Divider>
                        </Box>
                        <Box mt={1} style={{ width: '100%' }}>
                            <Alert severity="info"
                                action={
                                    <Button color="primary" size="small"
                                        variant='outlined'
                                        onClick={() => this.setState({ workSearchDialog: true })}
                                    >
                                        Hours Request (Unallocated tasks)
                                    </Button>
                                }
                            >Notification - Kindly use this option to request hours for the tasks you are working, but not yet assigned in PaRaM. Please click the button to request.</Alert>
                        </Box>
                        <Box mt={1} style={{ width: '100%' }}>
                            <Tasks data={this.props.data ? this.props.data : null} refreshData={this.refreshData} refreshSubTender={this.refreshSubTender} ref={this.taskref} />
                        </Box>

                        <Box mt={1} style={{ width: '100%', height: 310 }}>
                            <UserProductivityReport uid={user.id} />
                        </Box>

                    </Grid>
                }

                <div>
                    <Dialog
                        fullScreen
                        open={this.state.workSearchDialog}
                        onClose={workSearchDialogClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar position='relative'>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={workSearchDialogClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" >
                                    Work Search
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <OutOfWork uid={user.id} />
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default withSnackbar(MyTasks)