import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import Moment from 'react-moment';
import { blue } from '@material-ui/core/colors';
import { getCookie } from '../Helper/CookieHelper';
import { withStyles } from '@material-ui/styles';
import TimeSheetOps from '../TimeSheet/TimeSheetOps';
import TimeSheetOpsView2 from '../TimeSheet/TimeSheetOpsView2';
import { errorMessage } from '../Helper/ErrorMessage';
import BulkApprovalSelection from '../TimeSheet/BulkApprovalSelection';
import UserTimesheetReport from '../Reports/UserTimesheetReport/UserTimesheetReport';
import { getPrimaryColor } from '../Helper/CommonThings';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

var momentRange = require('moment-range');
momentRange.extendMoment(moment);

const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));
const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ManageTimeSheet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            isLoading3: true,
            isTableLoading: true,
            selectDate: null,
            checkedB: true,
            tabledata: [],
            openCalc: false,
            timesheetname: "",
            tsid: 0,
            tabledata2: [],
            tabledata3: [],
            // usersLookUp: {},
            viewOnly: false,
            openBulkApproval: false,
            openTimesheetReport: false,
            timesheetDialogContent: true,
            timesheetData: [],
            tsDataIndex: null,
            prevDisabled: false,
            nextDisabled: false,
            value: '1',
            startDate: moment().subtract(30, 'days'),
            endDate: moment().add(45, 'days'),
            maxDate: moment().add(375, 'days')
        }
        this.refreshCount = this.refreshCount.bind(this)
    }

    componentDidMount() {
        this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&duration=13&start=${moment(this.state.startDate).format('DD-MM-YYYY')}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`);
    }

    fetchOps(url) {
        // this.setState({ isTableLoading: true, })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    tabledata: res.data,
                    isLoading: false,
                    isTableLoading: false,
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchTeamsData(url) {
        // this.setState({ isLoading3: true })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res2 => {
                this.setState({
                    tabledata3: res2.data,
                    isLoading3: false
                });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchAllData(url) {
        if (access && access.access && access.access.l2a && access.access.l2a.l2a_c) {
            // this.setState({ isLoading2: true })
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res2 => {
                    this.setState({
                        tabledata2: res2.data,
                        isLoading2: false
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    handleData(date) {
        var dataTo = [];
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (moment().range(moment(element.from_date), moment(element.to_date)).contains(date)) {
                dataTo.push(element);
            }
        }
        this.setState({ tabledata: dataTo })
    }

    prevNextChange(action) {
        this.setState({ timesheetDialogContent: true })
        setTimeout(() => {
            var tsid = this.state.tsid
            var tsData = this.state.timesheetData
            var tsDataIndex = this.state.tsDataIndex

            for (let i = 0; i < tsData.length; i++) {
                const element = tsData[i];
                if (element.id === tsid) {
                    this.setState({ tsDataIndex: i })
                    break
                }
            }
            if (action === 'prev') {
                tsDataIndex = tsDataIndex - 1
                tsid = tsData[tsDataIndex].id
                let timesheetname = "Timesheet booked by " + tsData[tsDataIndex].user_name
                this.setState({ tsid, timesheetname, timesheetDialogContent: false, tsDataIndex, prevDisabled: tsDataIndex === 0 ? true : false, nextDisabled: tsDataIndex === (tsData - 1) ? true : false })
            } else if (action === 'next') {
                tsDataIndex = tsDataIndex + 1
                tsid = tsData[tsDataIndex].id
                let timesheetname = "Timesheet booked by " + tsData[tsDataIndex].user_name
                this.setState({ tsid, timesheetname, timesheetDialogContent: false, tsDataIndex, prevDisabled: tsDataIndex === 0 ? true : false, nextDisabled: tsDataIndex === (tsData.length - 1) ? true : false })
            }
        }, 500);

    }

    refreshCount() {
        if (this.props.refreshCount) {
            this.props.refreshCount()
            this.refreshData()
        }
    }

    getWithDates() {
        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please Select Start and End Dates!', {
                variant: 'warning'
            });
        }
        // else if (moment(this.state.startDate).isBefore(this.state.endDate)) {
        //     this.props.enqueueSnackbar('End Date is greater than Start Date!', {
        //         variant: 'warning'
        //     });
        // } 
        else if (moment(this.state.startDate).isAfter(this.state.endDate)) {
            this.props.enqueueSnackbar('Start Date should be lesser than End Date!', {
                variant: 'warning'
            });
        } else {
            this.setState({ selectDate: null })
            if (this.state.value === '1') {
                this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&duration=13&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`);
            } else if (this.state.value === '2') {
                this.fetchTeamsData(`/bb/api/task/approve/limited/approval_data/?lead_id=${user.id}&duration=6&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
            } else if (this.state.value === "3") {
                this.fetchAllData(`/bb/api/task/approve/limited/approval_data/?duration=3&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
            }
        }
    }

    openTimesheet(rowData) {
        var tsid = rowData.id
        var tsData = this.state.tabledata
        for (let i = 0; i < tsData.length; i++) {
            const element = tsData[i];
            if (element.id === tsid) {
                this.setState({ tsDataIndex: i }, () => {
                    this.setState({
                        tsid: rowData.id,
                        timesheetname: "Timesheet booked by " + rowData.user_name,
                        openCalc: true,
                        viewOnly: false,
                        timesheetDialogContent: false,
                        timesheetData: this.state.tabledata,
                        prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                        nextDisabled: (this.state.tsDataIndex === (this.state.tabledata.length - 1)) ? true : false
                    })
                })
                break
            }
        }
    }

    openTimesheet1(rowData) {
        var tsid = rowData.id
        var tsData = this.state.tabledata3
        for (let i = 0; i < tsData.length; i++) {
            const element = tsData[i];
            if (element.id === tsid) {
                this.setState({ tsDataIndex: i }, () => {
                    this.setState({
                        tsid: rowData.id,
                        timesheetname: "Timesheet booked by " + rowData.user_name,
                        openCalc: true,
                        viewOnly: false,
                        timesheetDialogContent: false,
                        timesheetData: this.state.tabledata3,
                        prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                        nextDisabled: (this.state.tsDataIndex === (this.state.tabledata3.length - 1)) ? true : false
                    })
                })
                break
            }
        }
    }

    openTimesheet2(rowData) {
        var tsid = rowData.id
        var tsData = this.state.tabledata2
        for (let i = 0; i < tsData.length; i++) {
            const element = tsData[i];
            if (element.id === tsid) {
                this.setState({ tsDataIndex: i }, () => {
                    this.setState({
                        tsid: rowData.id,
                        timesheetname: "Timesheet booked by " + rowData.user_name,
                        openCalc: true,
                        viewOnly: false,
                        timesheetDialogContent: false,
                        timesheetData: this.state.tabledata2,
                        prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                        nextDisabled: (this.state.tsDataIndex === (this.state.tabledata2.length - 1)) ? true : false
                    })
                })
                break
            }
        }
    }

    refreshData() {
        if (this.state.value === '1') {
            this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&duration=13&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`);
        } else if (this.state.value === '2') {
            this.fetchTeamsData(`/bb/api/task/approve/limited/approval_data/?lead_id=${user.id}&duration=6&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
        } else if (this.state.value === '3') {
            this.fetchAllData(`/bb/api/task/approve/limited/approval_data/?duration=3&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
        }
    }

    render() {

        const { classes } = this.props;

        const handleClose2 = () => {
            this.setState({ openCalc: false }, () => {
                // this.refreshData();
            })
        };

        const handleClose3 = () => {
            this.setState({ openBulkApproval: false });
        };

        const handleDateChange = (date) => {
            // console.log(this.state.selectTime1)
            this.setState({ selectDate: date, checkedB: false, });
            this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&date=${moment(date).format('YYYY-MM-DD')}`)
            // this.handleData(date);

        };

        const handleChange = (event) => {
            this.setState({ checkedB: event.target.checked, selectDate: null });
            if (event.target.checked) {
                this.setState({ tabledata: this.state.data })
                this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&duration=13&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`);
            } else {
                this.fetchOps(`/bb/api/task/approve/limited/approval_data/?req_id=${user.id}&date=${moment().format('YYYY-MM-DD')}`);
                // this.handleData(this.state.selectDate);
            }
        };

        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };

        const handleTabChange = (event, newValue) => {
            if (newValue === null) {
                return
            }
            this.setState({
                value: newValue,
                startDate: moment().subtract(30, 'days'),
                endDate: moment().add(45, 'days'),
                maxDate: moment().add(375, 'days')
            }, () => {
                if (this.state.value === '2') {
                    this.setState({
                        maxDate: moment().add(185, 'days')
                    })
                    this.fetchTeamsData(`/bb/api/task/approve/limited/approval_data/?lead_id=${user.id}&duration=6&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
                } else if (this.state.value === "3") {
                    this.setState({
                        maxDate: moment().add(45, 'days')
                    })
                    this.fetchAllData(`/bb/api/task/approve/limited/approval_data/?duration=3&start=${moment(this.state.startDate).format("DD-MM-YYYY")}&end=${moment(this.state.endDate).format('DD-MM-YYYY')}`)
                }
            })
        };

        return (

            <div>
                {(this.state.isLoading) ?
                    <LoopCircleLoading color={getPrimaryColor()} />
                    :
                    <div>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Box flexGrow={1}>
                                <ToggleButtonGroup orientation="horizontal" value={this.state.value} exclusive onChange={handleTabChange}>
                                    <ToggleButton value="1" >
                                        Your Portfolio
                                    </ToggleButton>
                                    <ToggleButton value="2" >
                                        Your Team Members Timesheets
                                    </ToggleButton>
                                    {(access && access.access && access.access.l2a && access.access.l2a.l2a_c) ?
                                        <ToggleButton value="3">
                                            All Timesheets
                                        </ToggleButton> : null}
                                </ToggleButtonGroup>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog1"
                                        label="Start Date"
                                        format="dd/MM/yyyy"
                                        style={{ width: '30%', marginRight: '20px' }}
                                        value={this.state.startDate}
                                        onChange={(date) => {
                                            if (date) {
                                                this.setState({ startDate: date, endDate: moment(date).add('30', 'days') })
                                            } else {
                                                this.setState({ startDate: date, maxDate: moment().add(20, 'years') })
                                            }
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog1"
                                        label="End Date"
                                        format="dd/MM/yyyy"
                                        style={{ width: '30%', marginRight: '20px' }}
                                        maxDate={this.state.maxDate}
                                        value={this.state.endDate}
                                        onChange={(date) => {
                                            if (date) {
                                                this.setState({ endDate: date })
                                            } else {
                                                this.setState({ endDate: date })
                                            }
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <Button size='small' variant='contained' color='primary' onClick={() => this.getWithDates()}>Get</Button>
                            </Box>
                        </Box>

                        {this.state.value === '1' ?
                            <Box>
                                <Box display={'flex'} flexDirection={'row'} justifyContent="space-evenly" alignItems={'center'} marginBottom={'5px'}>
                                    <Box>
                                        <FormGroup row style={{ marginRight: '15px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.checkedB}
                                                        onChange={handleChange}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Show All Request"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Select date of a Week"
                                                format="dd/MM/yyyy"
                                                style={{ marginLeft: '10px' }}
                                                value={this.state.selectDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    {(access && access.access && access.access.l2a && access.access.l2a.l2a_e) ? (
                                        <Box>
                                            <Button style={{ marginLeft: '15px' }} variant="contained" color="primary" onClick={() => {
                                                this.setState({
                                                    openBulkApproval: true
                                                })
                                            }}>Bulk Approval</Button>
                                        </Box>)
                                        : null}
                                    <Box>
                                        <Button style={{ marginLeft: '15px' }} variant='outlined' color="primary" onClick={() => this.setState({ openTimesheetReport: true })} >Timesheet Report</Button>
                                    </Box>
                                </Box>

                                <MaterialTable
                                    title="Your Portfolio"
                                    columns={[
                                        {
                                            title: 'Emp name', field: 'user_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                                        },
                                        { title: 'Team', field: 'gname', headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 }, },
                                        // { title: 'Comment', field: 'comment', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                        // {
                                        //     title: 'Date Requested', field: 'date_created',
                                        //     render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                        // },
                                        // {
                                        //     title: 'Request Type', field: 'request_type',
                                        //     lookup: { 0: 'Initial Data', 1: 'Edited Data' },
                                        // },
                                        {
                                            title: 'Task From', field: 'from_date', type: 'date'
                                            // render: rowData => <Typography>{moment(rowData.from_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Task To', field: 'to_date', type: 'date'
                                            // render: rowData => <Typography>{moment(rowData.to_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Status', field: 'approve_status',
                                            lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Edit Requested', 4: 'Pending, Partially Approved', 5: 'Pending, Partially Rejected', 6: 'Approved, Rejected', 7: "Deleted" },
                                        },
                                        {
                                            title: 'Replied By', field: 'replied_by',
                                            // render: rowData => 
                                        },
                                    ]}
                                    data={this.state.tabledata}
                                    isLoading={this.state.isTableLoading}
                                    actions={(access && access.access && access.access.l2a && access.access.l2a.l2a_b) ? (
                                        [
                                            rowData => ({
                                                icon: 'open_in_browser',
                                                tooltip: 'Open in Dialog',
                                                onClick: (event, rowData) => this.openTimesheet(rowData),
                                                // onClick: (event, rowData) => {
                                                //     var tsid = rowData.id
                                                //     var tsData = this.state.tabledata
                                                //     for (let i = 0; i < tsData.length; i++) {
                                                //         const element = tsData[i];
                                                //         if (element.id === tsid) {
                                                //             this.setState({ tsDataIndex: i }, () => {
                                                //                 this.setState({
                                                //                     tsid: rowData.id,
                                                //                     timesheetname: "Timesheet booked by " + rowData.user_name,
                                                //                     openCalc: true,
                                                //                     viewOnly: false,
                                                //                     timesheetDialogContent: false,
                                                //                     timesheetData: this.state.tabledata,
                                                //                     prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                                                //                     nextDisabled: (this.state.tsDataIndex === (this.state.tabledata.length - 1)) ? true : false
                                                //                 })
                                                //             })
                                                //             break
                                                //         }
                                                //     }
                                                // },
                                            }),
                                            rowData => ({
                                                icon: 'launch',
                                                tooltip: 'Open in New Tab',
                                                onClick: (event, rowData) => { window.open("/approval/" + rowData.id) },
                                            }),

                                            (access.access.l2a.l2a_d) ? (
                                                {
                                                    icon: 'description',
                                                    tooltip: 'Timesheet Report',

                                                    isFreeAction: true,
                                                    onClick: () => {
                                                        window.open("/timesheet/report")
                                                    },
                                                }
                                            ) : (
                                                null
                                            ),

                                            (access.access.l111a && access.access.l111a.l111a_c) ? (
                                                {
                                                    icon: 'description',
                                                    tooltip: 'Available Workbook Report',

                                                    isFreeAction: true,
                                                    onClick: () => {
                                                        window.open("/report/alltask/1")
                                                    },
                                                }
                                            ) : (
                                                null
                                            ),

                                            (access.access.l111a && access.access.l111a.l111a_c) ? (
                                                {
                                                    icon: 'description',
                                                    tooltip: 'Estimates Report Report',

                                                    isFreeAction: true,
                                                    onClick: () => {
                                                        window.open("/report/alltask/0")
                                                    },
                                                }
                                            ) : (
                                                null
                                            ),

                                        ]
                                    ) : (null)}
                                    options={{
                                        padding: "dense",
                                        paging: false,
                                        pageSizeOptions: [10, 20, 50],
                                        filtering: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Box> : null}

                        {this.state.value === '2' ?
                            <Box marginTop={2}>
                                <MaterialTable
                                    style={{ width: '100%' }}
                                    title={<p>Data shown in below table is only for past 6 months</p>}
                                    columns={[
                                        {
                                            title: 'Emp name', field: 'user_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                                        },
                                        // {
                                        //     title: 'Emp name', field: 'uid', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                        //     lookup: this.state.usersLookUp
                                        // },
                                        { title: 'Team', field: 'gname', headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 }, },
                                        // { title: 'Comment', field: 'comment', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                        {
                                            title: 'Sent to', field: 'request_to_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                            // lookup: this.state.usersLookUp
                                        },
                                        // {
                                        //     title: 'Date Requested', field: 'date_created',
                                        //     render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                        // },
                                        // {
                                        //     title: 'Request Type', field: 'request_type',
                                        //     lookup: { 0: 'Initial Data', 1: 'Edited Data' },
                                        // },

                                        {
                                            title: 'Task From', field: 'from_date',
                                            render: rowData => <Typography>{moment(rowData.from_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Task To', field: 'to_date',
                                            render: rowData => <Typography>{moment(rowData.to_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Status', field: 'approve_status',
                                            lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Edit Requested', 4: 'Pending, Partially Approved', 5: 'Pending, Partially Rejected', 6: 'Approved, Rejected', 7: 'Deleted' },
                                        },
                                        {
                                            title: 'Replied By', field: 'replied_by',
                                            // render: rowData => 
                                        },
                                    ]}
                                    data={this.state.tabledata3}
                                    isLoading={this.state.isLoading3}
                                    actions={(access && access.access && access.access.l2a && access.access.l2a.l2a_b) ? (
                                        [
                                            rowData => ({
                                                icon: 'open_in_browser',
                                                tooltip: 'Open in Dialog',
                                                onClick: (event, rowData) => { this.openTimesheet1(rowData) },
                                                // onClick: (event, rowData) => {
                                                //     var tsid = rowData.id
                                                //     var tsData = this.state.tabledata3
                                                //     for (let i = 0; i < tsData.length; i++) {
                                                //         const element = tsData[i];
                                                //         if (element.id === tsid) {
                                                //             this.setState({ tsDataIndex: i }, () => {
                                                //                 this.setState({
                                                //                     tsid: rowData.id,
                                                //                     timesheetname: "Timesheet booked by " + rowData.user_name,
                                                //                     openCalc: true,
                                                //                     viewOnly: false,
                                                //                     timesheetDialogContent: false,
                                                //                     timesheetData: this.state.tabledata3,
                                                //                     prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                                                //                     nextDisabled: (this.state.tsDataIndex === (this.state.tabledata3.length - 1)) ? true : false
                                                //                 })
                                                //             })
                                                //             break
                                                //         }
                                                //     }
                                                // },
                                            }),
                                        ]
                                    ) : (null)}
                                    options={{
                                        padding: "dense",
                                        paging: false,
                                        pageSizeOptions: [10, 20, 50],
                                        filtering: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Box>
                            : null}

                        {this.state.value === '3' ?
                            <Box marginTop={2}>
                                <MaterialTable
                                    title={<p>Data shown in below table is only for past 3 months</p>}
                                    style={{ width: '100%' }}
                                    // style={{ marginTop: '15px' }}
                                    columns={[
                                        {
                                            title: 'Emp name', field: 'user_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                                        },
                                        // {
                                        //     title: 'Emp name', field: 'uid', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                        //     lookup: this.state.usersLookUp
                                        // },
                                        { title: 'Team', field: 'gname', headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 }, },
                                        // { title: 'Comment', field: 'comment', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                        {
                                            title: 'Sent to', field: 'request_to_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                            // lookup: this.state.usersLookUp
                                        },
                                        // {
                                        //     title: 'Date Requested', field: 'date_created',
                                        //     render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                        // },
                                        // {
                                        //     title: 'Request Type', field: 'request_type',
                                        //     lookup: { 0: 'Initial Data', 1: 'Edited Data' },
                                        // },
                                        {
                                            title: 'Task From', field: 'from_date',
                                            render: rowData => <Typography>{moment(rowData.from_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Task To', field: 'to_date',
                                            render: rowData => <Typography>{moment(rowData.to_date).format('DD/MM/YYYY')}</Typography>
                                        },
                                        {
                                            title: 'Status', field: 'approve_status',
                                            lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Edit Requested', 4: 'Pending, Partially Approved', 5: 'Pending, Partially Rejected', 6: 'Approved, Rejected', 7: 'Deleted' },
                                        },
                                        {
                                            title: 'Replied By', field: 'replied_by',
                                            // render: rowData => 
                                        },
                                    ]}
                                    data={this.state.tabledata2}
                                    isLoading={this.state.isLoading2}
                                    actions={(access && access.access && access.access.l2a && access.access.l2a.l2a_b) ? (
                                        [
                                            rowData => ({
                                                icon: 'open_in_browser',
                                                tooltip: 'Open in Dialog',
                                                onClick: (event, rowData) => this.openTimesheet2(rowData),
                                                // onClick: (event, rowData) => {
                                                //     var tsid = rowData.id
                                                //     var tsData = this.state.tabledata2
                                                //     for (let i = 0; i < tsData.length; i++) {
                                                //         const element = tsData[i];
                                                //         if (element.id === tsid) {
                                                //             this.setState({ tsDataIndex: i }, () => {
                                                //                 this.setState({
                                                //                     tsid: rowData.id,
                                                //                     timesheetname: "Timesheet booked by " + rowData.user_name,
                                                //                     openCalc: true,
                                                //                     viewOnly: false,
                                                //                     timesheetDialogContent: false,
                                                //                     timesheetData: this.state.tabledata2,
                                                //                     prevDisabled: this.state.tsDataIndex === 0 ? true : false,
                                                //                     nextDisabled: (this.state.tsDataIndex === (this.state.tabledata2.length - 1)) ? true : false
                                                //                 })
                                                //             })
                                                //             break
                                                //         }
                                                //     }
                                                //     // this.setState({
                                                //     //     tsid: rowData.id,
                                                //     //     timesheetname: "Timesheet booked by " + rowData.user_name,
                                                //     //     openCalc: true,
                                                //     //     viewOnly: false
                                                //     // })
                                                // },
                                            }),
                                            rowData => ({
                                                icon: 'launch',
                                                tooltip: 'Open in New Tab',
                                                onClick: (event, rowData) => { window.open("/approval/" + rowData.id) },
                                            }),
                                        ]
                                    ) : (null)}
                                    options={{
                                        padding: "dense",
                                        paging: true,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        filtering: true,
                                        exportButton: { csv: true },
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Box> : null}

                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {this.state.timesheetname}
                                    </Typography>
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} startIcon={<ArrowBackIosIcon />} style={{ marginRight: '10px' }} disabled={this.state.prevDisabled} onClick={() => this.prevNextChange('prev')}>Prev</Button>
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} endIcon={<ArrowForwardIosIcon />} disabled={this.state.nextDisabled} onClick={() => this.prevNextChange('next')}>Next</Button>
                                </Toolbar>
                            </AppBar>
                            {this.state.timesheetDialogContent ? <LoopCircleLoading /> : (this.state.viewOnly) ? (<TimeSheetOpsView2 value={this.state.tsid} />) : (<TimeSheetOps value={this.state.tsid} refreshCount={this.refreshCount} />)}

                        </Dialog>

                        <Dialog
                            open={this.state.openBulkApproval}
                            onClose={handleClose3}
                            fullWidth={true}
                            maxWidth="xl"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >

                            <DialogContent>
                                <BulkApprovalSelection refreshCount={this.refreshCount} />
                            </DialogContent>

                        </Dialog>

                        <Dialog
                            open={this.state.openTimesheetReport}
                            maxWidth={"lg"}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Report will shared via E-mail
                                </DialogContentText>
                                <UserTimesheetReport teamUsers={true} userEnabled={true} />
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleDialogClose} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div >
                }
            </div >

        )
    }
}

export default withStyles(useStyles)(withSnackbar(ManageTimeSheet));